import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { PrivateRoute } from '../routes/PrivateRoute';
import { UserState } from '../store/user/types';
import { fetchUser } from '../store/user/actions';
import { ApplicationState } from '../store/types';
import Globalstyle from '../global-styles';
import { isPostDayOne } from '../components/HomeSelector';
import TestError from '../components/TestError';
import usePageLoadTracking from '../metrics/usePageLoadTracking';
import useRUM from '../monitoring/hooks/useRum';

import loadable from '@loadable/component';
import { heartBeatAction } from '../store/heartbeat/actions';

import { persistHostApp } from '../store/environment/actions';

import { HostAppContext } from '../types/hostApp';

import { PreboardingHostAppContext, prefixSegmentToPath } from '../context/baseSegmentContext';

const BadgeUploadPage = loadable(() => import(/* webpackChunkName: "BadgeUploadPage" */ '../pages/BadgeUploadPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../components/LandingPage'));
const UnAuthorized = loadable(() => import(/* webpackChunkName: "Unauthorized401" */'../components/unathorized'));
const NotFoundPage = loadable(/* webpackChunkName: "NotFoundPagae" */ () => import('../components/notfound'));
const CatchAll = loadable(/* webpackChunkName: "CatchAll" */() => import('../components/CatchAll'));
const FdiePage = loadable(() => import(/* webpackChunkName: "FdiePage" */ '../pages/FdiePage'));
const IntroduceYourselfPage = loadable(() => import(/* webpackChunkName: "IntroduceYourselfPage" */ '../pages/IntroduceYourselfPage'));
const ReturnItEquipmentPage = loadable(() => import(/* webpackChunkName: "ReturnItEquipmentPage" */ '../pages/ReturnItEquipmentPage/ReturnItEquipmentPage'));
const ImmigrationPage = loadable(() => import(/* webpackChunkName: "ImmigrationPage" */ '../pages/ImmigrationPage/ImmigrationPage'));
const DrugScreenStatusPage = loadable(() => import(/* webpackChunkName: "DrugScreenStatusPage" */ '../pages/DrugScreenStatusPage'));
const IspPage = loadable(() => import(/* webpackChunkName: "IspPage" */ '../pages/IspPage'));
const PostDayOne = loadable(() => import(/* webpackChunkName: "PostDayOne" */ '../components/PostDayOne'));
const MedicalCheckPage = loadable(() => import(/* webpackChunkName: "MedicalCheckPage" */ '../pages/MedicalCheckPage'));
const MyDocsPage = loadable(() => import(/* webpackChunkName: "MyDocsPage" */ '../pages/MyDocsPage'));
const PicPage = loadable(() => import(/* webpackChunkName: "PicPage" */ '../pages/PicPage'));
const LoginSelectionPage = loadable(() => import(/* webpackChunkName: "LoginSelectionPage" */ '../pages/LoginSelectionPage'));
const BackgroundCheckPage = loadable(() => import(/* webpackChunkName: "BackgroundCheckPage" */ '../pages/BackgroundCheckPage'));
const ShippingAddressPage = loadable(() => import(/* webpackChunkName: "ShippingAddressPage" */ '../pages/ShippingAddressPage'));
const TaskPage = loadable(() => import(/* webpackChunkName: "TaskPage" */ '../components/TaskPage'));
const DashboardPage = loadable(() => import('../pages/DashboardPage'));
const FaqPage = loadable(() => import('../pages/FaqPage'));


export interface AppStateProps {
  user: UserState;
}

interface AppDispatchProps {
  init: (context: HostAppContext) => void;
  comeAlive: (user: UserState) => void;
}

type AppProps = AppStateProps & AppDispatchProps;

const validUser = (user: UserState): boolean => !!(user && user.profile);

const App = ({ user, init, comeAlive }: AppProps) => {

  const hostAppContext = useContext<HostAppContext>(PreboardingHostAppContext);
  const baseSegmentPrefix = hostAppContext.baseSegmentPrefix;

  useRUM(user, hostAppContext.hostApp);
  useEffect(() => {
    init(hostAppContext);
  }, []);

  useEffect(() => {
    if (validUser(user)) {
      usePageLoadTracking(window.location.pathname, baseSegmentPrefix);
      comeAlive(user);
    }
  });

  return (
    <Router>
      <Globalstyle/>
      {user && user.profile?.joiningDate !== undefined && isPostDayOne(user) ? (
        <PostDayOne/>
      ) : (
        <Switch>
          <Route
            user={user}
            exact
            path={prefixSegmentToPath({ path: '/', prefixSegment: baseSegmentPrefix })}
            render={(props: RouteComponentProps) =>
              props.location.hash === '#badge-upload-v1' ? (
                <Redirect to="/task/badge"/>
              ) : (
                <PrivateRoute
                  user={user}
                  path={prefixSegmentToPath({ path: '/', prefixSegment: baseSegmentPrefix })}
                  exact
                  component={DashboardPage}/>
              )
            }
          />
          <Route path="/landing" component={LandingPage}/>
          <Route path="/401" component={UnAuthorized}/>
          <Route path="/catchall" component={CatchAll}/>
          <Route path="/test-error" component={TestError}/>
          <Route path="/task" exact>
            <Redirect to="/"/>
          </Route>
          <Route path="/dashboard" exact>
            <Redirect to="/"/>
          </Route>
          <Redirect from="/plan/:planId" to="/task/:planId"/>
          <PrivateRoute
            path="/task/badge"
            user={user}
            exact
            component={BadgeUploadPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/bgc"
            user={user}
            exact
            component={BackgroundCheckPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/first-day-itinerary"
            user={user}
            exact
            component={FdiePage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/return-it-equipment"
            user={user}
            exact
            component={ReturnItEquipmentPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/introduce-yourself"
            user={user}
            exact
            component={IntroduceYourselfPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/immigration"
            user={user}
            exact
            component={ImmigrationPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/drug-screen-status"
            user={user}
            exact
            component={DrugScreenStatusPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/immigration-service-provider"
            user={user}
            exact
            component={NotFoundPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/login-selection"
            user={user}
            exact
            component={LoginSelectionPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/medical-check"
            user={user}
            exact
            component={MedicalCheckPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            path="/task/mydocs"
            user={user}
            exact
            component={MyDocsPage}
            componentProps={{ user }}
          />
          <PrivateRoute path="/task/pic" user={user} exact component={PicPage} componentProps={{ user }}/>
          <PrivateRoute
            path="/task/shipping-address"
            user={user}
            exact
            component={ShippingAddressPage}
            componentProps={{ user }}
          />
          <PrivateRoute
            componentProps={{ inTaskContent: true }}
            user={user}
            path="/task/:planId"
            exact
            component={TaskPage}
          />
          <PrivateRoute
            user={user}
            path="/faq"
            exact
            component={FaqPage}
          />
          <Route path="*" component={NotFoundPage}/>
        </Switch>
      )}
    </Router>
  );
};

const mapStateToProps = (state: ApplicationState): AppStateProps => ({
  user: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): AppDispatchProps => {
  return {
    comeAlive: (user: UserState) => {
      if (user.isAuthenticated) dispatch(heartBeatAction());
    },
    init: (context: HostAppContext) => {
      dispatch(persistHostApp(context));
      dispatch(fetchUser());
    }
  };
};

export { App };

export default connect(mapStateToProps, mapDispatchToProps)(App);
